import { Location, NavigateFunction } from 'react-router-dom';
import { callOff } from '../components/Telephone/Telephone';
import { InitialTerminierungType } from '../context/terminierungContext';
import { getBaseURL } from '../globals/global';
import { checkEnrollCodeViaWS } from './RestServices';

const getPeerConnectionConfig: any = (myIceServers: RTCPeerConnection) => {
    if (myIceServers) {
        const iceServers = { iceServers: myIceServers };
        return iceServers;
    }
    return {
        iceServers: [
            { urls: ['stun:87.190.43.213:3478', 'stun:eu-turn4.xirsys.com'] },
            { username: 'coturn', credential: 'coturn2020', urls: ['turn:87.190.43.213:3478'] },
        ],
    };
};

/* ### PING INTERVAL HANDLING ### */
let pingInterval: any = 0;
const setPingInterval = () => {
    pingInterval = setInterval(() => {
        if ((window as any).app.wsconnect) {
            try {
                (window as any).app.wsconnect.send('PINGOPEN...');
            } catch (ex) {}
        } else {
            clearPingInterval();
        }
    }, 30000);
};

const clearPingInterval = () => {
    clearInterval(pingInterval);
};
/* ############################### */

const closeWebSocket = (vsdispatch: React.Dispatch<any>) => {
    clearPingInterval();
    vsdispatch({
        type: 'ONDISCONNECT',
        incomingCall: true,
    });
};

const leaveSession = (
    vsdispatch: any,
    tmstate: InitialTerminierungType,
    navigate: NavigateFunction,
    location: Location,
) => {
    if ((window as any).app.wsconnect.remoteStream) {
        (window as any).app.wsconnect.remoteStream = null;
    }
    if ((window as any).app.wsconnect.peerConnection) {
        (window as any).app.wsconnect.peerConnection.close();
        (window as any).app.wsconnect.peerConnection = null;
    }

    callOff(navigate, location, false, vsdispatch, tmstate);
};

const createdDescription = (description: RTCSessionDescriptionInit, remoteSid: string, sessionId: string) => {
    (window as any).app.wsconnect.peerConnection
        .setLocalDescription(description)
        .then(function () {
            (window as any).app.wsconnect.send(
                JSON.stringify({
                    rtc: 'true',
                    sdp: (window as any).app.wsconnect.peerConnection.localDescription,
                    remoteSid: remoteSid,
                    mySid: sessionId,
                }),
            );
        })
        .catch(function (error: any) {
            console.log('setLocalDescription failed for ', remoteSid, ': ', error);
        });
};

export const initWebSocket = (
    iceServers: Array<string>,
    sessionId: string,
    vsstate: any,
    vsdispatch: React.Dispatch<any>,
    tmstate: InitialTerminierungType,
    navigate: NavigateFunction,
    location: Location,
) => {
    if ((window as any).app.wsconnect) {
        // (window as any).app.wsconnect.close();
        return (window as any).app.wsconnect;
    }

    const baseURL = getBaseURL();
    const wsLocation = baseURL.replace(/http/, 'ws') + '/pcpmsg';
    (window as any).app.wsconnect = new WebSocket(wsLocation);

    //console.log('initialised WebSocket ' + wsLocation, (window as any).app.wsconnect);

    (window as any).app.wsconnect.onopen = () => {
        const wsc = (window as any).app.wsconnect;
        if (wsc) {
            if (tmstate.withLogin) {
                wsc.send('PCPWEBAPPCONNECTIONSTART:' + sessionId);
            } else {
                // console.log('sending enrollCode: ' + sessionId);
                const enrollData: any = {
                    enrollCode: sessionId,
                    isWebApp: true,
                };
                // Beim Resfresh kann es sein, dass der open den close ueberholt.
                const tellEnrollId = (lnr) => {
                    wsc.send(JSON.stringify(enrollData));
                    window.setTimeout(function () {
                        if (!checkEnrollCodeViaWS(sessionId)) {
                            if (lnr > 0) {
                                lnr -= 1;
                            }
                            tellEnrollId(lnr);
                        }
                    }, 1000);
                };
                tellEnrollId(10);
            }
        }

        setPingInterval();
    };

    (window as any).app.wsconnect.onerror = (err: string) => {
        console.log('Socket encountered an error', err, ' ... Closing socket');
        (window as any).app.wsconnect = null;
        closeWebSocket(vsdispatch);
    };

    (window as any).app.wsconnect.onclose = (e: any) => {
        console.log('Socket is closed.', e.reason);
        (window as any).app.wsconnect = null;
    };

    const getLocalStream = async () => {
        if (!vsstate.localStream) {
            //console.log('chat.startVideo: addStream with chat.localVideoStream done');
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            vsdispatch({
                type: 'ONLOCALSTREAM',
                localStream: stream,
            });
            (window as any).app.wsconnect.peerConnection.addStream(stream);
        }
    };

    (window as any).app.wsconnect.onmessage = (msg: any) => {
        if (!window.app.allowVideochatMessages) {
            return;
        }
        if (msg.data.startsWith('{')) {
            const msgdata = JSON.parse(msg.data);

            //console.log('wsConnection onmessage SUCCESS');

            if (msgdata.cmd === 'call') {
                (window as any).app.wsconnect.peerConnection = new RTCPeerConnection(
                    getPeerConnectionConfig(iceServers),
                );

                const remoteSid = msgdata.mySid;
                vsdispatch({
                    type: 'ONMESSAGE',
                    incomingCall: true,
                    caller: msgdata.caller,
                    remoteSid: remoteSid,
                    roomName: msgdata.remoteSid,
                });

                getLocalStream();

                (window as any).app.wsconnect.peerConnection.oniceconnectionstatechange = () => {
                    try {
                        if (
                            (window as any).app.wsconnect.peerConnection.iceConnectionState &&
                            (window as any).app.wsconnect.peerConnection.iceConnectionState === 'disconnected'
                        ) {
                            (window as any).setTimeout(function () {
                                leaveSession(vsdispatch, tmstate, navigate, location);
                            }, 100);
                        }
                    } catch (error) {
                        //console.log('iceConnectionState error');
                    }
                };
            } else if (msgdata.cmd === 'newSchedulingTime') {
                //TODO: implement me
                //console.log('msgdata = newSchedulingTime');
            } else if (msgdata.cmd === 'leaveSession') {
                leaveSession(vsdispatch, tmstate, navigate, location);
            } else if (msgdata.cmd === 'newChat') {
                /* Appointment Chat Handling */
                if (msgdata.appId && !msgdata.enrollCode) {
                    /* set the chat messages only when the chat is open, otherwise not necessary until we have no notification service */
                    vsdispatch({
                        type: 'SETLASTINCOMINGCHATMESSAGES',
                        lastIncomingAppChatMsg: {
                            id: msgdata.appId,
                            incomingMessages: msgdata.chat,
                        },
                    });
                    /* Soforttermin Chat */
                } else {
                    if (msgdata.chat) {
                        vsdispatch({
                            type: 'SETCHATMESSAGES',
                            chatMessages: msgdata.chat,
                        });
                        vsdispatch({
                            type: 'INCREASENUMBERUNREADMESSAGES',
                        });
                    }
                    //console.log('msgdata = newChat');
                }
            }

            if (msgdata.sdp) {
                (window as any).app.wsconnect.peerConnection
                    .setRemoteDescription(new RTCSessionDescription(msgdata.sdp))
                    .then(function () {
                        // Only create answers in response to offers
                        if (msgdata.sdp.type === 'offer') {
                            (window as any).app.wsconnect.peerConnection
                                .createAnswer()
                                .then(function (description: RTCSessionDescriptionInit) {
                                    createdDescription(description, msgdata.mySid, sessionId);
                                })
                                .catch(function (error: any) {
                                    console.log('got data.sdp(offer), but error in createAnswer: ', error);
                                });
                        }
                    })
                    .catch(function (error: any) {
                        console.log('got data.spd, but error in setRemoteDescripition: ', error);
                    });
            } else if (msgdata.ice) {
                if ((window as any).app.wsconnect.peerConnection) {
                    (window as any).app.wsconnect.peerConnection
                        .addIceCandidate(new RTCIceCandidate(msgdata.ice))
                        .catch(function (error: any) {
                            console.log(
                                'got data.ice, but error in addIceCandidate: ' +
                                    error +
                                    '(data.ice=' +
                                    JSON.stringify(msgdata.ice) +
                                    ')',
                            );
                        });
                }
            }

            return;
        }
    };

    return (window as any).app.wsconnect;
};
